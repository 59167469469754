p,
li,
ul {
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  padding: 1em;
}

li:hover,
:focus {
  background-color: seashell;
}

a {
  text-decoration: none;
  color: black;
}

li a:visited {
  color: gray;
}

.container {
  width: 100%;
  max-width: 675px;
  margin: 0 auto;
}

.specialty {
  font-weight: bold;
}

.specialty-highlight {
  color: tomato;
}
